import useRest from 'helpers/hooks/useRest';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

import Cookie from '@lyearn/core/utils/cookie';

export const useLyearnCorpAccessToken = () => {
  const { site } = useInstanceConfig();
  const [, , , executeGenerateToken] = useRest('/external/generateTokens/lyearnCorporateTokens', {
    method: 'POST',
    body: JSON.stringify({
      action: 'fetchAuthToken',
      lyearnCorporateCompanyId: site.corpCompanyId,
      accessToken: Cookie.get('accessToken'),
    }),
    headers: {
      'content-type': 'application/json',
    },
  });

  const executeGenerateCorpToken = async () => {
    const response = await executeGenerateToken();
    if (response && response.success) {
      Cookie.set('lyearn_token', response.tokens.accessToken);
      return response.tokens.accessToken;
    } else {
      // TODO: Error handling using snackbars
      console.log('Error in getting learning token', response);
    }
  };

  return [executeGenerateCorpToken];
};

export default useLyearnCorpAccessToken;
