import { useEffect, useState } from 'react';
import calculateDiscount from 'helpers/calculateDiscount';
import { useProductQuery } from 'modules/product/graphql/queries/generated/product';

export const useProductCheckout = ({ productId }: { productId: string }) => {
  const [purchasable, setPurchasable] = useState<boolean | null>(null);
  const [fetching, setFetching] = useState(true);

  const [{ fetching: fetchingProduct, error, data }] = useProductQuery({
    variables: {
      filters: { id: productId },
    },
  });

  useEffect(() => {
    if ((purchasable !== null && !fetchingProduct) || (error && !data?.product)) {
      setFetching(false);
    }
  }, [fetchingProduct, purchasable, error, data]);

  useEffect(() => {
    if (!fetchingProduct) {
      const product = data?.product;
      const pricingParameters = product?.pricingParameters;

      if (pricingParameters) {
        const { discountedPrice } = calculateDiscount(pricingParameters!);

        setPurchasable(discountedPrice > 0);
      }
    }
  }, [error, data, fetchingProduct]);

  return { fetching, product: data?.product, purchasable };
};

export default useProductCheckout;
