import React from 'react';
import LayoutPage from 'components/layoutPage/LayoutPage';
import config from 'config';
import getSystemUserAccessToken from 'helpers/getSystemUserAccessToken';
import affiliations, { AffiliationsValue } from 'modules/contentAffiliations';
import ProductPageCompoundContext from 'modules/context/CompoundContexts/ProductPageContext';
import ProductPaymentStatus from 'modules/product/components/PaymentStatus';
import ProductSEO from 'modules/product/components/SEO';
import fetchProduct from 'modules/product/helpers/fetchProduct';
import fetchProducts from 'modules/product/helpers/fetchProducts';
import getProductPaths from 'modules/product/helpers/getProductPaths';
import { ProductStatusEnum } from 'modules/product/types';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from 'next';
import { ProductTypeEnum } from 'types/schema';

import { Box, Container } from '@lyearn/ui';

export default function ProductPage({
  pageProduct,
  contentCurriculum,
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const { pages, site } = useInstanceConfig();

  if (!pageProduct) {
    return null;
  }

  const type = pageProduct.type;

  return (
    <>
      <ProductPageCompoundContext contentCurriculum={contentCurriculum} products={[pageProduct]}>
        <Box>
          <ProductPaymentStatus />
          <Container component="main">
            <LayoutPage
              layoutConfig={
                type === ProductTypeEnum.Collection
                  ? pages.PRODUCT_COLLECTION_PAGE_LAYOUT
                  : pages.PRODUCT_PAGE_LAYOUT
              }
            />
          </Container>
        </Box>
        <ProductSEO host={site.host} />
      </ProductPageCompoundContext>
    </>
  );
}

export const query = `
query products ($filters: GetProductsFilters, $first: Float){
  products(filters: $filters, first: $first) {
    edges{
      node{
        id,
        productIds
        SEOSettings {
          slug
        }
      }
    }
  }
}`;

export const getStaticPaths: GetStaticPaths = async (context) => {
  const token = getSystemUserAccessToken(config.default_build_hosts[0]);

  const products = await fetchProducts(
    {
      first: 5,
      filters: {
        status: [ProductStatusEnum.Listed],
      },
    },
    { token },
  );

  let paths = getProductPaths({ products });

  config.default_build_hosts.forEach((host) => {
    paths = paths.map((p) => {
      (p as any).params.site = host;
      return p;
    });
  });

  return {
    paths,
    fallback: 'blocking',
  };
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { product_slug } = context.params as TSFixMe;
  const token = getSystemUserAccessToken(context.params?.site);

  const product = await fetchProduct(
    {
      filters: {
        slug: product_slug,
      },
    },
    { token },
  );

  const affiliation = affiliations({
    product: product!,
    accessToken: token,
  }) as AffiliationsValue;

  const contentCurriculum = await affiliation?.getContentCurriculum?.();

  const props = {
    pageProduct: product,
    contentCurriculum,
    product_slug,
  };

  return {
    props,
    revalidate: 180,
  };
};
