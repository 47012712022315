import PaymentStatusModal from 'components/PaymentStatusModal';
import { useProductContext } from 'modules/context/Contexts/Product';

const ProductPaymentStatus: React.FC = () => {
  const { product } = useProductContext();
  const { id } = product;

  return <PaymentStatusModal product={product} productId={id} url="/[product_slug]" />;
};

export default ProductPaymentStatus;
