import { useCallback } from 'react';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';

export const useTransactionGoogleConversion = () => {
  const { site } = useInstanceConfig();
  const { CONVERSION_ID, CONVERSION_LABEL } = site?.integrations?.google || {};

  const triggerConversion = useCallback(() => {
    if ((window as any)?.gtag && CONVERSION_ID && CONVERSION_LABEL) {
      (window as any)?.gtag('event', 'conversion', {
        send_to: `${CONVERSION_ID}/${CONVERSION_LABEL}`,
      });
    }
  }, [CONVERSION_ID, CONVERSION_LABEL]);

  return { triggerConversion };
};

export default useTransactionGoogleConversion;
