import React from 'react';
import { UserProduct } from 'modules/product/types';

import { ProductContextProvider } from '../Contexts/Product';

type ProductPageCompoundContextProps = {
  products: UserProduct[];
  contentCurriculum: any;
};

const ProductPageCompoundContext: React.FC<
  React.PropsWithChildren<ProductPageCompoundContextProps>
> = (props) => {
  return <ProductContextProvider {...props} />;
};

export default ProductPageCompoundContext;
