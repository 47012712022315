import React, { useEffect, useState } from 'react';
import cn from 'classnames';
// import useOpenLearningSpace from 'hooks/useOpenLearningSpace';
import useCheckoutSessionAndRedirect from 'data-op/actions/useCheckoutSessionAndRedirect';
import { useUser } from 'modules/context/Contexts/User';
import { useTransactionGoogleConversion } from 'modules/payment';
import useProductActions from 'modules/product/hooks/useProductActions';
import { GenericProduct, UserProduct } from 'modules/product/types';
import getProductUrls from 'modules/product/utils/getProductUrls';
import { useRouter } from 'next/router';
import { UserStatusEnum } from 'types/schema';

import { ArrowForward, Close } from '@lyearn/icons';
import { Box, Button, Dialog, Typography } from '@lyearn/ui';

import { FailureMessageData, ModalData, SuccessMessageData } from './Messages';
import { buttonStyle, contentStyle, dialogStyle } from './styles';

type ModalStateType = {
  show: boolean;
  data: ModalData;
  isSuccess: boolean;
};

const defaultModal = {
  show: false,
  data: {},
  isSuccess: false,
};

type Props = {
  product: GenericProduct;
  productId: string;
  url?: string;
};

const PaymentStatusModal: React.FC<Props> = ({ url = '/[product_slug]', productId, product }) => {
  const router = useRouter();
  const user = useUser();
  const userEmailNotVerified = user.status === UserStatusEnum.EmailNotVerified;
  // const { openLearningSpace } = useOpenLearningSpace();
  const { triggerConversion } = useTransactionGoogleConversion();

  const { checkout } = useCheckoutSessionAndRedirect({
    productId: productId,
    quantity: 1,
    product,
    redirectUrl: getProductUrls({ product }).productUrl,
  });

  const { handleStartLearning } = useProductActions({
    product,
    redirectUrl: getProductUrls({ product }).productUrl,
  });

  const [modal, setModal] = useState<ModalStateType>(defaultModal);

  useEffect(() => {
    if (router.query.status === 'success') {
      setModal({ show: true, data: SuccessMessageData, isSuccess: true });

      /* GA Conversion call for Google Ads */
      triggerConversion();
    } else if (router.query.status === 'cancel' || router.query.status === 'failed') {
      setModal({ show: true, data: FailureMessageData, isSuccess: false });
    }

    /* ref: https://github.com/vercel/next.js/discussions/18072 */
    window.history.replaceState(
      { ...window.history.state, as: window.location.pathname, url: url },
      '',
      window.location.pathname,
    );
  }, [router, url, triggerConversion]);

  const clickHandler = () => {
    if (modal.isSuccess === true) {
      handleStartLearning();
      // openLearningSpace(contentIdToOpen, productId, false, isChildProduct);
    } else {
      checkout();
    }
    setModal({ show: false, data: {}, isSuccess: false });
  };

  return (
    <Dialog classes={{ paper: cn(dialogStyle, 'px-20 pt-26 pb-50 relative') }} open={modal.show}>
      <Close
        className="absolute mb-26 h-26 w-26 cursor-pointer self-end"
        onClick={() => {
          setModal({ show: false, data: {}, isSuccess: false });
        }}
      />
      <Box className={cn(contentStyle, 'grid text-center')}>
        <img alt="payment-status" className="mb-20 w-192" src={modal.data.imageUrl} />
        <Typography className="mb-6" variant="display-xxl">
          {modal.data.title}
        </Typography>
        <Typography className="mb-26 text-text-secondary" variant="body-short-02">
          {modal.data.body && modal.data.body(user.emailId)}
        </Typography>
        <Button
          className={cn(buttonStyle)}
          disabled={modal.isSuccess && userEmailNotVerified}
          onClick={clickHandler}>
          {modal.data.buttonText}
          <ArrowForward className="h-20 w-20 ms-12 rtl:rotate-180" />
        </Button>
      </Box>
    </Dialog>
  );
};

export default PaymentStatusModal;
