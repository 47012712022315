import { useProductContext } from 'modules/context/Contexts/Product';
import useInstanceConfig from 'modules/siteConfig/hooks/useInstanceConfig';
import { NextSeo, ProductJsonLd } from 'next-seo';

const ProductSEO = ({ host }: { host: string }) => {
  const { site } = useInstanceConfig();
  const { product } = useProductContext();
  const productName = product.name || '';
  const productDescription = product.description || '';
  const url = `https://${host}/${product.SEOSettings?.slug}`;
  let images = product.media?.filter((item) => item.type === 'IMAGE').map((item) => item.url);
  const { siteName, organizationName, twitterHandle, homepage } = site.seo as TSFixMe;

  return (
    <>
      <NextSeo
        canonical={url}
        description={productDescription}
        openGraph={{
          images: images?.map((image) => {
            return { url: image };
          }),
          title: productName,
          description: productDescription,
          site_name: siteName,
          type: 'website',
        }}
        title={productName}
        titleTemplate={`%s | ${homepage.title}`}
        twitter={{
          site: twitterHandle,
          cardType: 'summary_large_image',
        }}
      />
      <ProductJsonLd
        brand={organizationName}
        description={productDescription}
        images={images}
        offers={[
          {
            price: product.derivedFields.purchaseInfo.finalPrice.toString(),
            priceCurrency: product.derivedFields.purchaseInfo.currency,
            availability: 'http://schema.org/InStock',
            url,
            seller: {
              name: organizationName,
            },
          },
        ]}
        productName={productName}
      />
    </>
  );
};

export default ProductSEO;
