import gql from 'graphql-tag';
import * as Types from 'types/schema';
export type CourseContent_ContentType_Fragment = {
  __typename: 'ContentType';
  id: string;
  contentId?: string | null;
  type: Types.ProductContentTypeEnum;
  duration?: number | null;
  description?: string | null;
  media?: Array<{
    __typename?: 'DeprecatedMediaType';
    type: Types.MediaTypeEnum;
    url: string;
    resolutions: Array<{
      __typename?: 'DeprecatedResolutionType';
      type: Types.DeprecatedResolutionEnum;
      url: string;
    }>;
  }> | null;
};

export type CourseContent_CourseContentType_Fragment = {
  __typename: 'CourseContentType';
  id: string;
  name: string;
  description?: string | null;
  rootBlockId?: string | null;
  course_type: Types.ResourceTypeEnum;
  course_duration: number;
  instructors: Array<{
    __typename?: 'InstructorType';
    name: string;
    picture?: string | null;
    designation: string;
  }>;
  cover?: { __typename?: 'EntityMediaType'; kind: Types.MediaKindEnum; url?: string | null } | null;
};

export type CourseContent_PathContentType_Fragment = {
  __typename: 'PathContentType';
  id: string;
  name: string;
};

export type CourseContent_ProductCollectionContentType_Fragment = {
  __typename: 'ProductCollectionContentType';
  type: Types.ProductContentTypeEnum;
  duration?: number | null;
};

export type CourseContentFragment =
  | CourseContent_ContentType_Fragment
  | CourseContent_CourseContentType_Fragment
  | CourseContent_PathContentType_Fragment
  | CourseContent_ProductCollectionContentType_Fragment;

export const CourseContentFragmentDoc = gql`
  fragment CourseContent on ProductContentEntityUnion {
    __typename
    ... on ContentType {
      id
      contentId
      type
      duration
      description
      media {
        type
        url
        resolutions {
          type
          url
        }
      }
    }
    ... on CourseContentType {
      id
      name
      course_type: type
      course_duration: duration
      description
      rootBlockId
      instructors {
        name
        picture
        designation
      }
      cover {
        kind
        url
      }
    }
    ... on PathContentType {
      id
      name
    }
    ... on ProductCollectionContentType {
      type
      duration
    }
  }
`;
