
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/_sites/[site]/[product_slug]",
      function () {
        return require("private-next-pages/_sites/[site]/[product_slug]/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/_sites/[site]/[product_slug]"])
      });
    }
  