import { Typography } from '@lyearn/ui';

export type ModalData = {
  imageUrl?: string;
  title?: string;
  body?: Function;
  buttonText?: string;
};

const SuccessMessageData: ModalData = {
  imageUrl: 'https://d7jx2atmwmj9r.cloudfront.net/assets/images/payment-success.svg',
  title: 'Order Complete',
  body: (email: string) => (
    <>
      Thank you for your order. We've sent an <br /> invoice to{' '}
      <Typography className="text-link-01 underline" variant="body-short-02">
        {email}
      </Typography>
    </>
  ),
  buttonText: 'Start Learning',
};

const FailureMessageData: ModalData = {
  imageUrl: 'https://d7jx2atmwmj9r.cloudfront.net/assets/images/payment-failed.svg',
  title: 'Payment Failed',
  body: () => (
    <>
      Unfortunately, your payment could not be processed.
      <br />
      Please try again in some time.
    </>
  ),
  buttonText: 'Try Again',
};

export { FailureMessageData, SuccessMessageData };
