import { css } from '@emotion/css';

export const dialogStyle = css`
  min-width: 50rem;
  width: 30% !important;
  border-radius: 1.6rem !important;
`;

export const buttonStyle = css`
  width: max-content;
`;

export const contentStyle = css`
  justify-items: center;
`;
