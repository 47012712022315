import gql from 'graphql-tag';
import * as Types from 'types/schema';
import * as Urql from 'utils/urql';

import { ProductFragmentDoc } from '../../../../../data-op/fragments/generated/product';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type ProductQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.GetProductFilters>;
}>;

export type ProductQuery = {
  __typename?: 'Query';
  product?: {
    __typename?: 'ProductType';
    id: string;
    type: Types.ProductTypeEnum;
    description?: string | null;
    name?: string | null;
    status: Types.ProductStatusEnum;
    productIds: Array<string>;
    childProducts?: Array<{
      __typename?: 'ProductType';
      id: string;
      type: Types.ProductTypeEnum;
      description?: string | null;
      name?: string | null;
      status: Types.ProductStatusEnum;
      productIds: Array<string>;
      content?:
        | {
            __typename: 'ContentType';
            id: string;
            contentId?: string | null;
            type: Types.ProductContentTypeEnum;
            duration?: number | null;
            description?: string | null;
            media?: Array<{
              __typename?: 'DeprecatedMediaType';
              type: Types.MediaTypeEnum;
              url: string;
              resolutions: Array<{
                __typename?: 'DeprecatedResolutionType';
                type: Types.DeprecatedResolutionEnum;
                url: string;
              }>;
            }> | null;
          }
        | {
            __typename: 'CourseContentType';
            id: string;
            name: string;
            description?: string | null;
            rootBlockId?: string | null;
            course_type: Types.ResourceTypeEnum;
            course_duration: number;
            instructors: Array<{
              __typename?: 'InstructorType';
              name: string;
              picture?: string | null;
              designation: string;
            }>;
            cover?: {
              __typename?: 'EntityMediaType';
              kind: Types.MediaKindEnum;
              url?: string | null;
            } | null;
          }
        | { __typename: 'PathContentType'; id: string; name: string }
        | {
            __typename: 'ProductCollectionContentType';
            type: Types.ProductContentTypeEnum;
            duration?: number | null;
          }
        | null;
      pricingParameters?: {
        __typename?: 'PricingType';
        price?: number | null;
        currency: string;
        validity: string;
        coupons?: Array<string> | null;
        discount?: {
          __typename?: 'DiscountType';
          type: Types.DiscountTypeEnum;
          amount: number;
          validity?: string | null;
        } | null;
      } | null;
      SEOSettings?: {
        __typename?: 'SEOSettingsType';
        productTitle: string;
        productDescription: string;
        slug: string;
      } | null;
      generalSettings?: {
        __typename?: 'GeneralSettingsType';
        category: Array<string>;
        isBestSeller: boolean;
        label?: string | null;
        certificateOnCompletion: boolean;
      } | null;
      media: Array<{
        __typename?: 'DeprecatedMediaType';
        type: Types.MediaTypeEnum;
        url: string;
        resolutions: Array<{
          __typename?: 'DeprecatedResolutionType';
          type: Types.DeprecatedResolutionEnum;
          url: string;
        }>;
      }>;
    }> | null;
    content?:
      | {
          __typename: 'ContentType';
          id: string;
          contentId?: string | null;
          type: Types.ProductContentTypeEnum;
          duration?: number | null;
          description?: string | null;
          media?: Array<{
            __typename?: 'DeprecatedMediaType';
            type: Types.MediaTypeEnum;
            url: string;
            resolutions: Array<{
              __typename?: 'DeprecatedResolutionType';
              type: Types.DeprecatedResolutionEnum;
              url: string;
            }>;
          }> | null;
        }
      | {
          __typename: 'CourseContentType';
          id: string;
          name: string;
          description?: string | null;
          rootBlockId?: string | null;
          course_type: Types.ResourceTypeEnum;
          course_duration: number;
          instructors: Array<{
            __typename?: 'InstructorType';
            name: string;
            picture?: string | null;
            designation: string;
          }>;
          cover?: {
            __typename?: 'EntityMediaType';
            kind: Types.MediaKindEnum;
            url?: string | null;
          } | null;
        }
      | { __typename: 'PathContentType'; id: string; name: string }
      | {
          __typename: 'ProductCollectionContentType';
          type: Types.ProductContentTypeEnum;
          duration?: number | null;
        }
      | null;
    pricingParameters?: {
      __typename?: 'PricingType';
      price?: number | null;
      currency: string;
      validity: string;
      coupons?: Array<string> | null;
      discount?: {
        __typename?: 'DiscountType';
        type: Types.DiscountTypeEnum;
        amount: number;
        validity?: string | null;
      } | null;
    } | null;
    SEOSettings?: {
      __typename?: 'SEOSettingsType';
      productTitle: string;
      productDescription: string;
      slug: string;
    } | null;
    generalSettings?: {
      __typename?: 'GeneralSettingsType';
      category: Array<string>;
      isBestSeller: boolean;
      label?: string | null;
      certificateOnCompletion: boolean;
    } | null;
    media: Array<{
      __typename?: 'DeprecatedMediaType';
      type: Types.MediaTypeEnum;
      url: string;
      resolutions: Array<{
        __typename?: 'DeprecatedResolutionType';
        type: Types.DeprecatedResolutionEnum;
        url: string;
      }>;
    }>;
  } | null;
};

export const ProductDocument = gql`
  query product($filters: GetProductFilters) {
    product(filters: $filters) {
      ...Product
    }
  }
  ${ProductFragmentDoc}
`;

export function useProductQuery(options?: Omit<Urql.UseQueryArgs<ProductQueryVariables>, 'query'>) {
  return Urql.useQuery<ProductQuery, ProductQueryVariables>({ query: ProductDocument, ...options });
}
